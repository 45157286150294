import React, { Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loader from './component/loader';

import { firebaseAnalytics } from './firebase';
import { logEvent } from 'firebase/analytics';


// All Headers
const Header = React.lazy(() => import('./layouts/header/Header'))

// All Footers
const Footer = React.lazy(() => import('./layouts/footer/Footer'))
const BackTop = React.lazy(() => import('./component/back-top'))

const Home = React.lazy(() => import('./pages/Home'));
const AboutMain = React.lazy(() => import('./pages/about/AboutMain'));
const AboutUs = React.lazy(() => import('./pages/about/AboutUs'));
const OurTeam = React.lazy(() => import('./pages/about/OurTeam'));
const Career = React.lazy(() => import('./pages/about/Career'));

const SectorsMain = React.lazy(() => import('./pages/sectors/SectorsMain'));
const DefenceIndustry = React.lazy(() => import('./pages/sectors/DefenceIndustry'));
const IoT = React.lazy(() => import('./pages/sectors/IoT'));
const WebTechnologies = React.lazy(() => import('./pages/sectors/WebTechnologies'));
const UnmannedSystems = React.lazy(() => import('./pages/sectors/UnmannedSystems'));

const ServicesMain = React.lazy(() => import('./pages/services/ServicesMain'));
const EmbeddedSystems = React.lazy(() => import('./pages/services/EmbeddedSystems'));
const WebDevelopment = React.lazy(() => import('./pages/services/WebDevelopment'));
const DataAnalysisMachineLearning = React.lazy(() => import('./pages/services/DataAnalysisMachineLearning'));

const SystemArchitectureDesign = React.lazy(() => import('./pages/services/embedded-systems/SystemArchitectureDesign'));
const CommandControlApplications = React.lazy(() => import('./pages/services/embedded-systems/CommandControlApplications'));
const RealTimeOperatingSystems = React.lazy(() => import('./pages/services/embedded-systems/RealTimeOperatingSystems'));
const GraphicalUserInterface = React.lazy(() => import('./pages/services/embedded-systems/GraphicalUserInterface'));
const NetworkCommunications = React.lazy(() => import('./pages/services/embedded-systems/NetworkCommunications'));
const EmbeddedLinux = React.lazy(() => import('./pages/services/embedded-systems/EmbeddedLinux'));

const DataAnalytics = React.lazy(() => import('./pages/services/data-analysis-machine-learning/DataAnalytics'));
const DataVisualization = React.lazy(() => import('./pages/services/data-analysis-machine-learning/DataVisualization'));
const DataWarehouse = React.lazy(() => import('./pages/services/data-analysis-machine-learning/DataWarehouse'));
const SensorFusion = React.lazy(() => import('./pages/services/data-analysis-machine-learning/SensorFusion'));
const ReinforcementLearning = React.lazy(() => import('./pages/services/data-analysis-machine-learning/ReinforcementLearning'));
const Robotics = React.lazy(() => import('./pages/services/data-analysis-machine-learning/Robotics'));

const AmazonWebServices = React.lazy(() => import('./pages/services/web-development-cloud-applications/AmazonWebServices'));
const BackendDevelopment = React.lazy(() => import('./pages/services/web-development-cloud-applications/BackendDevelopment'));
const FrontendDevelopment = React.lazy(() => import('./pages/services/web-development-cloud-applications/FrontendDevelopment'));
const CI_CD = React.lazy(() => import('./pages/services/web-development-cloud-applications/CI-CD'));
const Databases = React.lazy(() => import('./pages/services/web-development-cloud-applications/Databases'));
const MicroservicesArchitecture = React.lazy(() => import('./pages/services/web-development-cloud-applications/MicroservicesArchitecture'));

const ProjectsMain = React.lazy(() => import('./pages/projects/ProjectsMain'));
const Prozek = React.lazy(() => import('./pages/projects/Prozek'));
const FalconCommander = React.lazy(() => import('./pages/projects/FalconCommander'));

const Contact = React.lazy(() => import('./pages/Contact'));
const Error404 = React.lazy(() => import('./pages/error404/error404'));

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const location = useLocation();
  const hideFooter = location.pathname !== '/coming-soon';

  React.useEffect(() => {
    // Log a page view event when the App component mounts
    const pageName = window.location.pathname;
    if (window.location.hostname !== "localhost") {
      logEvent(firebaseAnalytics, 'page_view', { page_name: pageName });
    }
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <ScrollToTop />
      <div className="main-wrapper">
        <Routes>
          <Route path="/*" element={<Header />} />
        </Routes>

        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/about" element={<AboutMain />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/career" element={<Career />} />

          <Route path="/sectors" element={<SectorsMain />} />
          <Route path="/sectors/defence-industry" element={<DefenceIndustry />} />
          <Route path="/sectors/unmanned-systems" element={<UnmannedSystems />} />
          <Route path="/sectors/iot" element={<IoT />} />
          <Route path="/sectors/web-technologies" element={<WebTechnologies />} />

          <Route path="/services" element={<ServicesMain />} />
          <Route path="/services/embedded-systems" element={<EmbeddedSystems />} />
          <Route path="/services/web-development" element={<WebDevelopment />} />
          <Route path="/services/data-analysis" element={<DataAnalysisMachineLearning />} />

          <Route path="/services/system-architecture-design" element={<SystemArchitectureDesign/>} /> 
          <Route path="/services/command-control-applications" element={<CommandControlApplications />} />
          <Route path="/services/real-time-operating-systems" element={<RealTimeOperatingSystems />} />
          <Route path="/services/graphical-user-interfaces" element={<GraphicalUserInterface />} />
          <Route path="/services/network-communications" element={<NetworkCommunications />} />
          <Route path="/services/embedded-linux" element={<EmbeddedLinux />} />

          <Route path="/services/data-analytics" element={<DataAnalytics />} />
          <Route path="/services/data-visualization" element={<DataVisualization />} />
          <Route path="/services/data-warehouses" element={<DataWarehouse />} />
          <Route path="/services/sensor-fusion" element={<SensorFusion />} />
          <Route path="/services/reinforcement-learning" element={<ReinforcementLearning />} />
          <Route path="/services/robotics" element={<Robotics />} />

          <Route path="/services/amazon-web-services" element={<AmazonWebServices />} />
          <Route path="/services/backend-development" element={<BackendDevelopment />} />
          <Route path="/services/frontend-development" element={<FrontendDevelopment />} />
          <Route path="/services/cont-integration-cont-delivery" element={<CI_CD />} />
          <Route path="/services/database" element={<Databases />} />
          <Route path="/services/microservices-architecture" element={<MicroservicesArchitecture />} />

          <Route path="/projects" element={<ProjectsMain />} />
          <Route path="/prozek" element={<Prozek />} />
          <Route path="/falcon-commander" element={<FalconCommander />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/*" element={<Error404 />} />
        </Routes>

        {/* All Footer */}
        {hideFooter && (
          <Routes>
            <Route path="/*" element={<Footer />} />{' '}
          </Routes>
        )}

        {/* Back to Top */}
        <BackTop />
      </div>
    </Suspense>
  );
}

export default App;
