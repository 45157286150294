// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWJ93B-SdETsoWNxmghiCnkT-Ha4z-IxA",
  authDomain: "website-akdogantech-6c1f3.firebaseapp.com",
  projectId: "website-akdogantech-6c1f3",
  storageBucket: "website-akdogantech-6c1f3.appspot.com",
  messagingSenderId: "724620062556",
  appId: "1:724620062556:web:6e32d1d242d4bb8212db99",
  measurementId: "G-86QVKWRP4C"
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);

export { firebaseApp, firebaseAnalytics }